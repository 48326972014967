.box {
    padding: 64px;
    border-radius: 16px;
    border: 4px solid #111;
    background-color: #000;
    color: #f5f5f5;
    width: auto;
    display: block;
    height: auto;
    // overflow: scroll;
}