.current-post {
    padding: 0px;
}

.posts {
    margin: 0 auto;
    width: 666px;
    padding: 16px;
    height: 80vh;
}

.post {
    &+.post {
        margin-top: 64px;
        border-top: 4px solid #111;
        padding-top: 64px;
    }

    &-date {
        background-color: #111;
        padding: 16px;
        color: #666;
    }

    &-title {
        font-size: 2.4rem;
        margin: 24px 0;
        line-height: 3.2rem;
    }

    &-body {
        p {
            margin: 16px 0;
        }

        h1 {
            font-size: 2.2rem;
            margin: 2.4rem 0 2.2rem;
        }

        h2 {
            font-size: 2rem;
            margin: 2.2rem 0 2rem;
        }

        h3 {
            font-size: 1.8rem;
            margin: 2rem 0 1.8rem;
        }

        h4 {
            font-size: 1.6rem;
            margin: 1.8rem 0 1.6rem;
        }

        h5 {
            font-size: 1.4rem;
            margin: 1.6rem 0 1.4rem;
        }

        h6 {
            font-size: 1.2rem;
            margin: 1.4rem 0 1.2rem;
        }

        b, strong {
            font-weight: 700;
            color: aqua;
        }

        small {
            font-size: 10px;
            background-color: #111;
            padding: 8px;
        }

        a {
            color: white;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        span {
            background-color: #111;
            padding: 8px;
        }

        .npf {
            &_quote {
                padding: 16px;
                background-color: #111;
                border-left: 4px solid #222;
                &:before, &:after {
                    content: '"';
                    display: inline-block;
                }
            }

            &_quirky {
                font-family: 'Flow Circular';
                letter-spacing: 10px;
                font-size: 1.4rem;
                color: yellow;
                &:hover {
                    font-size: 1rem;
                    letter-spacing: -0.1rem;
                    line-height: 2rem;
                    font-family: 'apple_regular', 'Space Mono', Courier, monospace;
                    color: #fff;
                }
            }

            &_indented {
                // font-family: 'Nixie One';
                // letter-spacing: 10px;
                // font-size: 1.4rem;
                color: pink;
                display: inline-block;
                width: 100%;
                border-left: 4px solid pink;
                padding-left: 1.4rem;
            }
        }

        ul {
            list-style-type: square;
            list-style-position: inside;
        }

        ol {
            list-style-type: lower-roman;
            list-style-position: inside;
        }

        figure {
            height: 100%;
            width: 100%;
            // border: 4px solid #111;
            // padding: 16px;
        }
    }

    &-tags {
        margin: 16px 0;
    }

    &-button {
        margin: 16px 0;
    }
}