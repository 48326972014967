@import '../../assets/StylesDefault.scss';

.button {
    &:not(.button-dark) {
        &::before { border: 1px solid $black; }
    }

    &-white {
        &::after {
            background-color: $white;
            border: 1px solid $black;
        }
        &:hover::after { border: 1px solid $black; }
    }
    &-pink {
        &::after {
            background-color: $pink;
            border: 1px solid $white;
        }
        &:hover::after { border: 1px solid $black; }
    }
    &-green {
        &::after {
            background-color: $green;
            border: 1px solid $white;
        }
        &:hover::after { border: 1px solid $black; }
    }
    &-blue {
        &::after {
            background-color: $blue;
            border: 1px solid $white;
        }
        &:hover::after { border-color: $black; }
    }
    &-yellow {
        &::after {
            background-color: $yellow;
            border: 1px solid $white;
        }
        &:hover::after { border-color: $black; }
    }
    &-orange {
        &::after {
            background-color: $orange;
            border: 1px solid $white;
        }
        &:hover::after { border-color: $black; }
    }
    &-gray {
        color: #BEC2C5;
        &::before { border: 1px solid #BEC2C5; }
        &::after {
            background-color: $black;
            border: 1px solid #BEC2C5;
        }
    }

    &::before { background-color: transparent; }

    // &-dark {
    //     &::after { border: 1px solid $black; }
    //     &::before { border: 1px solid $white; }
    // }

    &-black {
        text-shadow: 1px 1px 0 $gray;
        color: $white;
        &::after {
            background-color: $black;
            border: 1px solid $white;
        }
        &:hover::after { border-color: $white; }
    }

    &.disabled, &.pressed {
        color: $lightgray;
        padding: 11px 5px 5px 11px;
        z-index: 0;

        &::before {
            border-color: $gray;
        }
        &::after {
            border: 1px solid $gray;
            border-color: $gray;
        }
    }
}