@import '../../assets/StylesDefault.scss';

.button {
    position: relative;
    display: block;
    color: $black;
    z-index: 5;
    padding: 8px;
    margin-right: 3px;
    margin-bottom: 3px;
    letter-spacing: -2px;
    transition: all .05s ease-in-out;
    text-align: center;
    font-size: 10px;

    .content {
        z-index: 1;
        position: relative;
    }

    &:hover {
        padding: 11px 5px 5px 11px;
        &:after {
            bottom: -4px;
            right: -4px;
        }

        &::before {
            opacity: 0;
        }
    }

    &::after {
        content: '';
        display: block;
        border-radius: 8px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: all .05s ease-in-out;
    }

    &::before {
        content: '';
        display: block;
        border-radius: 8px;
        position: absolute;
        bottom: -4px;
        right: -4px;
        width: 100%;
        height: 100%;
        z-index: -2;
        transition: all .05s ease-in-out;
    }

    // &-icon {
    //     height: 20px;
    //     width: 20px;

    //     &::after {
    //         height: 20px;
    //         width: 20px;
    //     }

    //     &::before {
    //         height: 20px;
    //         width: 20px;
    //     }
    // }

    // &-icon {
    //     padding: 0px;
    //     align-items: center;
    //     justify-content: center;
    //     display: flex;

    //     .content {
    //         height: auto;
    //         width: auto;
    //         img { display: inline-block; }
    //     }

    //     &:hover { padding: 6px 0 0 6px; }
    // }
}