@import '../../assets/StylesDefault.scss';

.home {
    &_section {
        background-color: black;
        border: 1px solid cyan;

        // New Design System
        &-1 {
            height: 200px;
        }
    }

    &_intro {
        height: 50vh;
    }

    padding: 64px;
}

.go-down {
    width: 100%;
    height: 100px;
    display: block;
    position: relative;

    &:after {
        content: "";
        display: block;
        margin: 0;
        height: 100px;
        border: 1px solid cyan;
        width: 1px;
        position: absolute;
        top: 0;
        left: 50%;
    }
}

.box {
    padding: 0px;
    border: 0px;
    border-radius: 0px;
}

.boxes {
    height: 100%;
    &_box {
        width: 234px;
        background-color: rgba(255,255,255,0.05);
        height: 100%;
    }
}

.status-list {
    width: 100%;
    margin-left: 40px;

    ul {
        padding: 40px;
        background-color: rgba(0,0,0.3);

        li {
            display: inline-block;
            position: relative;
            width: 100%;
            padding: 24px 0;

            &:before {
                content: '';
                background-color: cyan;
                height: 15px;
                width: 15px;
                border-radius: 100%;
                display: block;
                margin: 24px;
                position: absolute;
                left: -73px;
                top: 7px;
            }
        }
    }
}

.social {

}