/**
* COLORS
*/

$white: #f5f5f5;
$lightgray: #999;
$gray: #333;
$darkgray: #1b1b1b;
$black: #111;
$green: #ADBA81;
$pink: #FDB5AA;
$yellow: #F6D646;
$blue: #AFF5FA;
$orange: #E76244;

.white { color: $white; }
.lightgray { color: $lightgray; }
.gray { color: $gray; }
.darkgray { color: $darkgray; }
.black { color: $black; }
.green { color: $green; }
.pink { color: $pink; }
.yellow { color: $yellow; }
.blue { color: $blue; }
.orange { color: $orange; }

/**
* FONTS
* export $coding
*/

@import url('https://fonts.googleapis.com/css2?family=Alike+Angular&family=Concert+One&display=swap');
// font-family: 'Alike Angular', serif;
// font-family: 'Concert One', cursive;

/**
* SELECTION
*/

::-moz-selection { /* Code for Firefox */
    color: $black;
    background: $white;
}

::selection {
    color: $black;
    background: $white;
}

/**
* FIGURE
*/

figure {
    width: 100%;
    height: auto;
    transition: all .2s ease-in-out;
    opacity: .5;
    filter: saturate(0%);

    &:hover {
        opacity: 1;
        transform: scale(101%);
        filter: saturate(100%);
    }
}

/**
* RESET THE HTML AND BODY
*/

// html {
//     font-size: 62.5%;
//     /* changes a default 16px font size to 10px */
// }

body {
    background-image: url('./media/background.png');
    background-position: top center;
    background-size: cover;
    color: $white;
    // font-size: 1.6rem;
    // letter-spacing: -0.1rem;
    // line-height: 2rem;
    margin: 0px;
    // font-family: $coding, 'Space Mono', Courier, monospace;
    // font-family: 'Space Mono', monospace;
    font-family: 'Alike Angular', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



/*
*  html5resetcss
*/
// html,div,span,object,iframe,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}
// body{outline:0;vertical-align:baseline;}
// article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
// nav ul{list-style:none}
// blockquote,q{quotes:none}
// blockquote:before,blockquote:after,q:before,q:after{content:none}
// a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;color: $orange;}
// ins{text-decoration:none}
// mark{font-style:italic;font-weight:bold}
// del{text-decoration:line-through}
// abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
// table{border-collapse:collapse;border-spacing:0}
// hr{display:block;height:1px;border:0;margin:1em 0;padding:0}
// input,select{vertical-align:middle}
h1, h2, h3, h4 {margin: 0.1rem 0; font-family: 'Concert One', cursive;}
h1 {font-size: 2rem;}
h2 {font-size: 1.5rem;}
h3 {font-size: 1.2rem;}
h4 {font-size: 1rem;}

$s-1: 4px;
$s-2: 8px;
$s-3: 16px;
$s-4: 24px;
$s-5: 32px;
$s-6: 40px;
$s-7: 56px;
$s-8: 64px;
$s-9: 80px;
$s-10: 128px;
$s-11: 256px;